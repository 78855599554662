import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors, ValidatorFn,
  Validators
} from "@angular/forms";
import { NgIf } from "@angular/common";
import { Answer } from "shared";
import { InjectBudgetService } from "mdef-services";

export function totalSumCheck(totalSum: number, totalValueAllowed: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valueGiven = Number(control.value);
    if ((valueGiven + totalSum) > totalValueAllowed) {
      return {totalSumPassed: 'totalSumPassed'}
    }
    return null;
  }
}

function findIndexOfMax(arr: number[]): number | null {
  if (arr.length === 0) return null; // Return null if the array is empty

  let maxIndex = 0;
  let maxValue = arr[0];

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > maxValue) {
      maxValue = arr[i];
      maxIndex = i;
    }
  }

  return maxIndex;
}

@Component({
  selector: 'app-currency-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './currency-input.component.html',
  styleUrl: './currency-input.component.scss'
})
export class CurrencyInputComponent implements AfterViewInit {
  @Input() totalSumAllowed: number = 50000;
  @Input() totalPrevious: number = 0;
  @Input() answers: Answer[] = [];
  @Input() questionLength: number = 1;
  @Output() valueSubmit: EventEmitter<Answer> = new EventEmitter<Answer>();

  @ViewChild('currencyInput') _currencyInput: ElementRef | undefined;

  showError: boolean = false;
  currencyForm: FormGroup;

  constructor(private injectBudgetService: InjectBudgetService) {
    this.currencyForm = new FormGroup({
      amount: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        totalSumCheck(this.injectBudgetService.totalBudgetSelection, this.totalSumAllowed)])
    });
  }

  ngAfterViewInit(): void {
    const currencyInput = this._currencyInput?.nativeElement as HTMLTextAreaElement;
    currencyInput.focus()
  }

  get errorMessage(): string {
    const currencyInputField = this.currencyForm.get('amount');
    if (currencyInputField && currencyInputField.errors && currencyInputField.errors) {
      const errors = Object.keys(currencyInputField.errors);
      switch (errors[0]) {
        case 'required':
          return 'Dit veld is verplicht';
        case 'pattern':
          return 'Het ingevoerde bedrag mag alleen cijfers bevatten. Gebruik geen punten, komma\'s of symbolen zoals het euroteken.';
        case 'totalSumPassed':
          return 'Het maximum te verdelen bedrag is €50000.';
        default:
          return 'error';
      }
    }
    return 'error';
  }

  onExplanationSubmit() {
    if (!this.currencyForm?.valid) {
      this.showError = true;
    } else if (this.answers.length >= 0) {
      this.showError = false;
      const inputValue: number = Number(this.currencyForm.get('amount')?.value);

      this.answers[0].amount = String(inputValue);
      this.injectBudgetService.addBudgetExpense(inputValue);

      if (this.injectBudgetService.currentBudgetSelection.length === this.questionLength) {
        const index: number | null = findIndexOfMax(this.injectBudgetService.currentBudgetSelection);
        if (index) {
          this.valueSubmit.emit(this.answers[index]);
        }
      } else {
        this.valueSubmit.emit(this.answers[0]);
      }
    }
  }
}
