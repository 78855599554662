import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForOf, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { SignUpFormComponent } from '../nickname/sign-up-form.component';
import { CharacterChoiceComponent } from '../character-choice/character-choice.component';
import { GroupSelectComponent } from '../group-select/group-select.component';
import { SvgIconComponent } from "component-library";

@Component({
  selector: 'app-intro-screen',
  standalone: true,
  imports: [
    NgStyle,
    NgForOf,
    NgOptimizedImage,
    NgIf,
    SignUpFormComponent,
    CharacterChoiceComponent,
    GroupSelectComponent,
    SvgIconComponent,
  ],
  templateUrl: './intro-screen.component.html',
  styleUrl: './intro-screen.component.scss',
})
export class IntroScreenComponent {
  characters: { label: string; introImage: string }[] = [
    {
      label: 'Officier zeedienst',
      introImage: 'assets/images/officier-zeedienst.png',
    },
    {
      label: 'Officier der mariniers',
      introImage: 'assets/images/officier-der-mariniers.png',
    },
    {
      label: 'Officier techniek',
      introImage: 'assets/images/officier-techniek.png',
    },
    {
      label: 'Officier logistiek',
      introImage: 'assets/images/officier-logistiek.png',
    },
  ];
  @Input() backgroundPath?: string;

  @Output() pageBack = new EventEmitter<boolean>();
  @Output() fieldClicked = new EventEmitter<string>();


  onFieldClicked(field: string) {
    this.fieldClicked.next(field);
  }

  onBackClicked(): void {
    this.pageBack.emit();
  }
}
