import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";

import {CommonModule} from "@angular/common";
import {interval, merge, takeUntil, tap, timer} from "rxjs";
import {Answer, Async} from "shared";

@Component({
  selector: 'app-explanation',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './explanation.component.html',
  styleUrl: './explanation.component.scss'
})
export class ExplanationComponent implements AfterViewInit {
  explanationTimer: number = 180000; // 3 minutes in ms
  explanationForm: FormGroup;
  showError = false;
  @Input() currentAnswer?: Answer | null;
  @Output() explanationSubmit = new EventEmitter<string>();

  @ViewChild('textArea') _textArea: ElementRef | undefined;
  @Input() explanation: Async<string>;

  constructor() {
    this.explanationForm = new FormGroup({
      explanation: new FormControl('', Validators.required),
    });

    const timerTimedOut$ = timer(this.explanationTimer).pipe(tap(() => {
      this.explanationForm.patchValue({
        explanation: 'De speler heeft geen toelichting gegeven bij het antwoord dat gekozen is'
      })
      this.onExplanationSubmit();
    }));

    interval(1000).pipe(
      takeUntil(merge(this.explanationSubmit, timerTimedOut$))
    ).subscribe();
  }

  ngAfterViewInit(): void {
    const textArea = this._textArea?.nativeElement as HTMLTextAreaElement;
    textArea.focus();
  }

  onExplanationSubmit() {
    if (this.explanationForm?.valid) {
      this.showError = true;

      this.explanationSubmit.next(this.explanationForm.value.explanation);
      this.explanationForm.reset();
    } else {
      this.showError = true;
    }
  }
}
