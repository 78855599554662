import {
  Component,
  EventEmitter, Inject,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Async } from 'shared';
import { GroupDTO, InjectDTO, ModuleDTO, PlayerDTO } from 'mdef-api';
import {
  ButtonComponent,
  FormCardComponent,
  FormGridComponent,
  FormSectionComponent,
  SelectComponent,
  TextInputComponent,
} from 'component-library';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-sign-up-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    TextInputComponent,
    ButtonComponent,
    FormGridComponent,
    FormCardComponent,
    FormSectionComponent,
  ],
  templateUrl: './sign-up-form.component.html',
  styleUrl: './sign-up-form.component.scss',
})
export class SignUpFormComponent {
  nickNameForm: FormGroup;
  showFormErrors: boolean = false;
  _groups: Async<Array<GroupDTO>>;
  _modules: Async<Array<ModuleDTO>>;
  @Input() injects: Async<Array<InjectDTO>>;
  @Output() nickNameFormSubmit = new EventEmitter<PlayerDTO>();
  @Input()
  set modules(value: Array<ModuleDTO> | null | undefined) {
    this._modules = value;
    if (value && !this.nickNameForm.get('moduleId')?.value) {
      this.nickNameForm.get('moduleId')?.setValue(value[0].id);
    }
  }

  @Input()
  set groups(value: Array<GroupDTO> | null | undefined) {
    this._groups = value;
    if (value && !this.nickNameForm.get('groupId')?.value) {
      this.nickNameForm.get('groupId')?.setValue(value[0].id);
    }
  }
  constructor(fb: FormBuilder, @Inject(DOCUMENT) private document: Document) {
    this.nickNameForm = fb.group({
      nickname: ['', Validators.required],
      groupId: [undefined, Validators.required],
      moduleId: [undefined, Validators.required],
    });

    this.elem = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
  }

  elem: HTMLElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };

  get groups() {
    return this._groups;
  }

  get modules() {
    return this._modules;
  }

  onNickNameFormSubmit() {
    if (this.nickNameForm?.valid) {
      this.nickNameFormSubmit.next(this.nickNameForm.value);
    } else {
      this.showFormErrors = true;
    }
  }
}
