import { Question } from "shared";

export const questions: Question[] = [
  {
    id: 1,
    label: 'Waar zou jij de prioriteit leggen?',
    questionTime: 20,
    timestamp: 114,
    videoPath: 'assets/videos/inject-9/KIM-Inject9-Intro+S1.mp4',
    answers: [
      {
        id: 10,
        followUpQuestionId: 2,
        label: 'De reparatie van de Helikopter',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?'
      },
      {
        id: 11,
        followUpQuestionId: 5,
        label: 'De reparatie van de 4-TONNER',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?'
      }
    ]
  },
  {
    id: 2,
    label: 'Hoe reageer je op de OGHLD?',
    questionTime: 20,
    timestamp: 15,
    videoPath: 'assets/videos/inject-9/KIM-Inject9-S2A.mp4',
    answers: [
      {
        id: 12,
        followUpQuestionId: 3,
        label: 'Spreek de OGHLD ter plekke aan op zijn manier van communiceren',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?'
      },
      {
        id: 13,
        followUpQuestionId: 4,
        label: 'Spreek de OGHLD later aan op zijn manier van communiceren',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?'
      }
    ]
  }, {
    id: 3,
    label: "Waarom heb je gekozen om zo te reageren? vul in",
    questionTime: 20,
    timestamp: 10,
    videoPath: 'assets/videos/inject-9/KIM-Inject9-S3A.mp4',
    type: 'open',
    intermediateResult: {
      videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
    }
  },
  {
    id: 4,
    label: "Waarom heb je ervoor gekozen om niet direct te reageren",
    questionTime: 20,
    timestamp: 4,
    videoPath: 'assets/videos/inject-9/KIM-Inject9-S3B.mp4',
    type: 'open',
    intermediateResult: {
      videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
    }
  },
  {
    id: 5,
    label: 'Hoe reageer je op de kapitein der mariniers?',
    questionTime: 20,
    timestamp: 15,
    videoPath: 'assets/videos/inject-9/KIM-Inject9-S2B.mp4',
    answers: [
      {
        id: 14,
        followUpQuestionId: 6,
        label: 'Spreek de kapitein ter plekke aan op zijn manier van communiceren',
      },
      {
        id: 15,
        followUpQuestionId: 7,
        label: 'Spreek de kapitein later aan op zijn manier van communiceren'
      }
    ]
  },
  {
    id: 6,
    label: "Welke reden kan er zijn om nu het gesprek aan te gaan?",
    questionTime: 20,
    timestamp: 10,
    videoPath: 'assets/videos/inject-9/KIM-Inject9-S3C.mp4',
    type: 'open',
    intermediateResult: {
      videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
    }
  },
  {
    id: 7,
    label: "Welke reden kan er zijn om later het gesprek aan te gaan?",
    questionTime: 20,
    timestamp: 4,
    videoPath: 'assets/videos/inject-9/KIM-Inject9-S3D.mp4',
    type: 'open',
    intermediateResult: {
      videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
    }
  },
];

export const firstQuestion: number = 1;
