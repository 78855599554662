import {Injectable} from "@angular/core";
import { QuestionAnswer } from "shared";
import * as DecisionTree1 from "./decision-tree-inject-1";
import * as DecisionTree2 from "./decision-tree-inject-2";
import * as DecisionTree4 from "./decision-tree-inject-4";
import * as DecisionTree3 from "./decision-tree-inject-3";
import * as DecisionTree5 from "./decision-tree-inject-5";
import * as DecisionTree6 from "./decision-tree-inject-6";
import * as DecisionTree7 from "./decision-tree-inject-7";
import * as DecisionTree8 from "./decision-tree-inject-8";
import * as DecisionTree9 from "./decision-tree-inject-9";
import * as DecisionTree10 from "./decision-tree-inject-10";

@Injectable({providedIn: 'root'})
export class QuestionAnswersPerInjectService {
  private questionsAndAnswersPerInject: Map<number, QuestionAnswer> = new Map<number, QuestionAnswer>();

  constructor() {
    this.questionsAndAnswersPerInject.set(1, new QuestionAnswer(DecisionTree1.questions, DecisionTree1.firstQuestion));
    this.questionsAndAnswersPerInject.set(2, new QuestionAnswer(DecisionTree2.questions, DecisionTree2.firstQuestion));
    this.questionsAndAnswersPerInject.set(3, new QuestionAnswer(DecisionTree3.questions, DecisionTree3.firstQuestion));
    this.questionsAndAnswersPerInject.set(4, new QuestionAnswer(DecisionTree4.questions, DecisionTree4.firstQuestion));
    this.questionsAndAnswersPerInject.set(5, new QuestionAnswer(DecisionTree5.questions, DecisionTree5.firstQuestion));
    this.questionsAndAnswersPerInject.set(6, new QuestionAnswer(DecisionTree6.questions, DecisionTree6.firstQuestion));
    this.questionsAndAnswersPerInject.set(7, new QuestionAnswer(DecisionTree7.questions, DecisionTree7.firstQuestion));
    this.questionsAndAnswersPerInject.set(8, new QuestionAnswer(DecisionTree8.questions, DecisionTree8.firstQuestion));
    this.questionsAndAnswersPerInject.set(9, new QuestionAnswer(DecisionTree9.questions, DecisionTree9.firstQuestion));
    this.questionsAndAnswersPerInject.set(10, new QuestionAnswer(DecisionTree10.questions, DecisionTree10.firstQuestion));
  }

  getQuestionAnswerForInject(injectId?: number) {
    if(injectId) {
      return this.questionsAndAnswersPerInject.get(injectId);
    } else {
      return undefined;
    }
  }
}
