import {Component} from '@angular/core';
import {filter, map, Observable, ReplaySubject} from "rxjs";
import {Answer, Async, Question} from "shared";
import {QuizService} from "../../services/quiz.service";
import {AsyncPipe, NgIf} from "@angular/common";
import {QuestionViewerComponent} from "../../question-viewer/question-viewer.component";
import {VideoPlayerComponent} from "../../video-player/video-player.component";
import {Router, RouterOutlet} from "@angular/router";
import {TopbarComponent} from "../../topbar/topbar.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-signup-page',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    QuestionViewerComponent,
    VideoPlayerComponent,
    RouterOutlet,
    TopbarComponent
  ],
  templateUrl: './quiz-page.component.html',
  styleUrl: './quiz-page.component.scss'
})
export class QuizPageComponent {
  private _play = new ReplaySubject<void>(1);
  private _pause = new ReplaySubject<void>(1);
  videoPath?: string;

  question$: Observable<Question | null>;

  showQuestion: boolean = false;

  constructor(private quizService: QuizService) {
    quizService.reset();
    this.question$ = quizService.currentQuestion$;

    this.quizService.currentStep$.pipe(untilDestroyed(this)).subscribe(step => {
      this.showQuestion = step.showQuestion;
      const newVideoPath = step.question?.videoPath ?? step.intermediateResult?.videoPath;

      if (newVideoPath !== this.videoPath) {
        this.videoPath = newVideoPath
        this._play.next();
      }
    });
  }

  get play$(): Observable<void> {
    return this._play.asObservable();
  }

  get pause$(): Observable<void> {
    return this._pause.asObservable();
  }

  onAnswerClicked($event: { answer?: Answer, time?: number, givenExplanation?: string }) {
    this.quizService.answer = $event;
  }

  onVideoEnd() {
    this.quizService.continue();
  }
}
