<form class="currency-input" [formGroup]="currencyForm" (ngSubmit)="onExplanationSubmit()">
  <div class="currency-input__wrapper">
    <div class="currency-input__example">Bijvoorbeeld 20000</div>
    <div class="currency-input__container">
      <label for="currency-input" class="currency-input__label">&euro;</label>
      <input id="currency-input" class="currency-input__input" type="text" formControlName="amount" #currencyInput/>
    </div>
    <div class="currency-input__error" *ngIf="!currencyForm.valid && showError">
      {{ errorMessage }}
    </div>
  </div>
  <button class="currency-input__submit" type="submit">Verstuur</button>
</form>
