import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {Answer, Question} from "../model";
import {QuestionAnswer} from "./question-answer";
import {map} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class QuestionAnswerService {
  private questionAnswer: ReplaySubject<QuestionAnswer> = new ReplaySubject<QuestionAnswer>(1);

  getFirstQuestion(): Observable<Question | null> {
    return this.questionAnswer.pipe(map(questionAnswer => questionAnswer.getFirstQuestion()));
  }

  getAnswerById(id: number): Observable<Answer | undefined> {
    return this.questionAnswer.pipe(map(questionAnswer => questionAnswer.getAnswerById(id)));
  }

  getQuestionById(id: number): Observable<Question | undefined> {
    return this.questionAnswer.pipe(map(questionAnswer => questionAnswer.getQuestionById(id)));
  }

  getQuestions(): Observable<Question[]> {
    return this.questionAnswer.pipe(map(questionAnswer => questionAnswer.getQuestions()));
  }

  getMaxDepthOfDecisionTree(): Observable<number> {
    return this.questionAnswer.pipe(map(questionAnswer => questionAnswer.getMaxDepthOfDecisionTree()));
  }

  setup(questionAnswer: QuestionAnswer) {
    this.questionAnswer.next(questionAnswer);
  }
}
