import {Question} from "shared";

export const questions: Question[] = [
  {
    id: 1,
    label: 'Hoe reageer je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-2/KIM-Inject2-Intro+S1.mp4',
    timestamp: 120,
    answers: [
      {
        id: 2,
        label: 'Spreek de roerganger aan op haar gedrag',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 2
      },
      {
        id: 6,
        label: 'Toon empathie doe haar werk erbij',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 3
      }
    ]
  },
  {
    id: 2,
    label: 'Hoe ga je om met deze situatie?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-2/KIM-Inject2-S2A.mp4',
    timestamp: 48,
    answers: [
      {
        id: 3,
        label: 'Ga het gesprek aan, verbeter de werkrelatie',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-2/KIM-Inject2-S3A.mp4',
        }
      },
      {
        id: 4,
        label: 'Kies voor de missie, ga later het gesprek aan',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-2/KIM-Inject2-S3B.mp4',
        }
      }
    ]
  },
  {
    id: 3,
    label: 'Je moet plots veel tegelijk doen, verander je de keuze?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-2/KIM-Inject2-S2B.mp4',
    timestamp: 53,
    answers: [
      {
        id: 7,
        label: 'Blijf het werk van OVDW en roerganger zelf doen',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-2/KIM-Inject2-S3C.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4'
          }
        }
      },
      {
        id: 8,
        label: 'Spreek de werkdruk uit en los het op met het team',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-2/KIM-Inject2-S3D.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4'
          }
        }
      }
    ]
  }
]

export const firstQuestion: number = 1;
