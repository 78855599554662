import {Answer, MultipleChoiceQuestion, Question} from "../model";

export class QuestionAnswer {
  private _questions: Question[] = [];
  private _answers: Answer[] = [];
  private maxDepth: number = 0;
  initQuestionId: number;

  constructor(questions: Question[], initQuestionId: number) {
    this.initQuestionId = initQuestionId;
    this._questions = questions;
    this._answers = this.abstractAllAnswers();

    this.calculateMaxDepth();

    const getHighestId = (arIn: Question[] | Answer[]) => [...arIn.map(q => q.id)].sort((a, b) => a - b).pop();
    if(this.getDuplicateQuestions().length) {
      console.error('question IDs are not unique; please check your decision tree, duplicates: ', this.getDuplicateQuestions(), `highest ID is: ${getHighestId(this._questions)}`);
    }
    if(this.getDuplicateAnswers().length) {
      console.error('answer IDs are not unique; please check your decision tree, duplicates: ', this.getDuplicateAnswers(), `highest ID is: ${getHighestId(this._answers)}`);
    }
  }

  getFirstQuestion() {
    return this.getQuestionById(this.initQuestionId) ?? null;
  }

  getAnswerById(id: number): Answer | undefined {
    return this._answers.find(answer => answer.id === id);
  }

  getQuestionById(id: number): Question | undefined {
    return this._questions.find(question => question.id === id);
  }

  getQuestions(): Question[] {
    return this._questions;
  }

  getAnswers(): Answer[] {
    return this._answers;
  }

  getMaxDepthOfDecisionTree(): number {
    return this.maxDepth;
  }

  private abstractAllAnswers(): Answer[] {
    return this._questions
      .filter(question => 'answers' in question && !!question.answers)
      .flatMap((question) => (question as MultipleChoiceQuestion).answers);
  }

  private getDuplicateQuestions(): number[] {
    return this.getDuplicates(this._questions.map(question => question.id));
  }

  private getDuplicateAnswers(): number[] {
    return this.getDuplicates(this._answers.map(answer => answer.id));
  }

  private getDuplicates(idsIn: number[]): number[] {
    const duplicates = [];
    const ids = [...idsIn];
    while(ids.length > 0) {
      const id = ids.pop();
      if(id && ids.includes(id)) {
        duplicates.push(id);
      }
    }
    return duplicates;
  }

  /**
   * calculated the longest path in the graph, excluding nodes already traveled in the current path
   * @private
   */
  private calculateMaxDepth() {
    const getDepthForQuestionId = (id: number | undefined, excludeIds: number[]) => {
      if(id) {
        const followupQuestion = this._questions.find(question => question.id === id);
        if(followupQuestion && !excludeIds.includes(followupQuestion.id)) {
          return getDepthForQuestion(followupQuestion, [...excludeIds, followupQuestion.id]);
        } else {
          return 0;
        }
      }  else {
        return 0;
      }
    };
    const getDepthForQuestion = (question: Question, excludeIds: number[] = []): number => 1 + Math.max(
      ...('answers' in question ?
        question.answers.map(answer => getDepthForQuestionId(answer.followUpQuestionId, excludeIds)) :
        'followUpQuestionId' in question ? [getDepthForQuestionId(question.followUpQuestionId, excludeIds)] : [0]
      )
    );
    this.maxDepth = Math.max(...this._questions.map(question => getDepthForQuestion.bind(this)(question)));
  }
}
