import {ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideHttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {BASE_PATH} from "mdef-api";
import { provideServiceWorker } from '@angular/service-worker';
import {MdefErrorHandlerService} from "../../../../library/shared/src/lib/error-handling/mdef-error-handler.service";
import {SharedModule} from "shared";

export const appConfig: ApplicationConfig = {
  providers: [provideHttpClient(), provideRouter(routes), { provide: BASE_PATH, useValue: environment.mdefService }, provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }), provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    { provide: ErrorHandler, useClass: MdefErrorHandlerService },
     importProvidersFrom(SharedModule.forRoot(environment))]
};
