import { Routes } from '@angular/router';
import {QuizPageComponent} from "./pages/quiz-page/quiz-page.component";
import {SignupPageComponent} from "./pages/signup-page/signup-page.component";

export const routes: Routes = [{
  path: 'quiz',
  component: QuizPageComponent
}, {
  path: 'signup',
  component: SignupPageComponent
}, {
  path: '',
  pathMatch: 'full',
  redirectTo: 'signup'
}];
