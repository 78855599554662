import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {fromEvent, map, merge, Observable, ReplaySubject} from "rxjs";
import {VideoActions} from "../models/video-actions";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.scss'
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {

  @Input({required: true}) play!: Observable<void>;
  @Input({required: true}) pause!: Observable<void>;
  @Input() videoPath!: string | undefined;
  @Output() videoEnd = new EventEmitter<any>;

  @ViewChild('videoElement') videoPlayer!: ElementRef<HTMLVideoElement>;
  private _videoActions = new ReplaySubject<VideoActions>(1);

  private fullScreenRequested = false;

  ngAfterViewInit(): void {
    fromEvent(this.videoPlayer?.nativeElement, 'ended').subscribe(this.videoEnd);
    this._videoActions.subscribe(action => {
      if (this.videoPlayer) {
        switch (action) {
          case "PLAY":
            // if (this.videoPlayer.nativeElement.requestFullscreen && !this.fullScreenRequested) {
            //   this.videoPlayer.nativeElement.requestFullscreen();
            //   this.fullScreenRequested = true;
            // }
            this.videoPlayer.nativeElement.pause();
            this.videoPlayer.nativeElement.load();
            this.videoPlayer.nativeElement.currentTime = 0;
            this.videoPlayer.nativeElement.play();
            break;
          case "PAUSE":
            this.videoPlayer.nativeElement.pause();
            break;
        }
      }
    })
  }

  ngOnInit(): void {
    merge(this.play.pipe(
        map(() => 'PLAY')),
      this.pause.pipe(
        map(() => 'PAUSE')))
      .subscribe((action) => {
        this._videoActions.next(action as VideoActions)
      })
  }
}
