import {Question} from "shared";

export const questions: Question[] = [
  {
    id: 1,
    label: 'Wat ga je doen?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-6/KIM-Inject6-S1.mp4',
    timestamp: 49,
    answers: [
      {
        id: 2,
        label: 'Open de deur',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 2
      },
      {
        id: 6,
        label: 'Laat de deur dicht',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 4
      }
    ]
  },
  {
    id: 2,
    label: 'Wat wil je doen?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-6/KIM-Inject6-S2A.mp4',
    timestamp: 23,
    answers: [
      {
        id: 3,
        label: 'Het risico lijkt te groot, sluit de deur',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-6/KIM-Inject6-S3A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-6/KIM-Inject6-Outro.mp4'
          }
        }
      },
      {
        id: 4,
        label: 'Je valt de brand aan, je wil de persoon koste wat het kost redden',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 3
      }
    ]
  },
  {
    id: 4,
    label: 'Wat doe je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-6/KIM-Inject6-S2B.mp4',
    timestamp: 14,
    answers: [
      {
        id: 7,
        label: 'Open de deur alsnog',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-6/KIM-Inject6-S3C.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-6/KIM-Inject6-Outro.mp4'
          }
        }
      },
      {
        id: 8,
        label: 'Open de deur niet',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-6/KIM-Inject6-S3D.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-6/KIM-Inject6-Outro.mp4'
          }
        }
      }
    ]
  },
  {
    id: 3,
    label: "Wat doe je?",
    questionTime: 20,
    videoPath: 'assets/videos/inject-6/KIM-Inject6-S3B.mp4',
    //TODO in de structuur lijkt hij te wijzen naar een keuzemoment maar waar dezelfde video uit moet komen, het kan zijn dat dit nog veranderd moet worden naar een antwoord met een extra videopad erin.
    timestamp: 16,
    answers: [
      {
        id: 5,
        label: 'Je ziet dat het niet gaat. En trekt je terug.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-6/KIM-Inject6-S3A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-6/KIM-Inject6-Outro.mp4'
          }
        }
      },
      {
        id: 9,
        label: 'Je zet door',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-6/KIM-Inject6-S4A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-6/KIM-Inject6-Outro.mp4'
          }
        }
      }
    ]
  }
]

export const firstQuestion: number = 1;
