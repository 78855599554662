import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {Answer, Question} from "shared";

@Component({
  selector: 'app-character-choice',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgOptimizedImage
  ],
  templateUrl: './character-choice.component.html',
  styleUrl: './character-choice.component.scss'
})
export class CharacterChoiceComponent {
  @Input() characters: { label: string, introImage: string }[] = [];
  @Output() fieldClicked = new EventEmitter<string>();

  onFieldClicked(field: string) {
    this.fieldClicked.next(field);
  }
}
