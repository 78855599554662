import {Question} from "shared";

export const questions: Question[] = [
  {
    id: 1,
    label: 'Voor welke optie kies je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-8/KIM-Inject8-Intro+S1.mp4',
    timestamp: 116,
    answers: [
      {
        id: 1,
        label: 'Kiest voor de helikopter.',
        followUpQuestionId: 2
      },
      {
        id: 2,
        label: 'Je kiest voor de 4-tonner.',
        followUpQuestionId: 3
      }
    ]
  },
  {
    id: 2,
    label: 'Kun je toelichten waarom je voor de helikopter hebt gekozen?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-8/KIM-Inject8-S2A.mp4',
    type: 'open',
    timestamp: 30,
    followUpQuestionId: 4
  },
  {
    id: 3,
    label: 'Kun je toelichten waarom je voor de 4-tonner hebt gekozen?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-8/KIM-Inject8-S2B.mp4',
    type: 'open',
    timestamp: 30,
    followUpQuestionId: 4
  },
  {
    id: 4,
    label: "Hoe reageer je?",
    questionTime: 20,
    videoPath: 'assets/videos/inject-8/KIM-Inject8-S3A.mp4',
    timestamp: 15,
    answers: [
      {
        id: 4,
        label: 'Zeg iets van haar manier van communiceren',
        hasExplanation: true,
        explanation: 'Kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-8/KIM-Inject8-S4A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      },
      {
        id: 5,
        label: 'Spreek de commandant later aan op haar manier van communiceren',
        hasExplanation: true,
        explanation: 'Kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-8/KIM-Inject8-S4B.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      },
    ]
  }
]

export const firstQuestion: number = 1;
