import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SelectComponent} from "component-library";
import { Async } from 'shared';
import {GroupDTO} from "mdef-api";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-group-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SelectComponent,
    NgIf
  ],
  templateUrl: './group-select.component.html',
  styleUrl: './group-select.component.scss'
})
export class GroupSelectComponent {
  groupSelectForm: FormGroup;
  @Output() groupFormSubmit = new EventEmitter<string>();
  @Input() groups!: Async<Array<GroupDTO>>;

  constructor() {
    this.groupSelectForm = new FormGroup({
      group: new FormControl('', Validators.required),
    });
  }

  onGroupFormSubmit() {
    if (this.groupSelectForm?.valid) {
      this.groupFormSubmit.next(this.groupSelectForm.value.nickName);
      this.groupSelectForm.reset()
    }
  }
}
