import {ApplicationConfig, ErrorHandler, forwardRef, importProvidersFrom, isDevMode, Provider} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {environment} from '../environments/environment';
import {BASE_PATH} from 'mdef-api';
import { provideServiceWorker } from '@angular/service-worker';
import {SharedModule} from 'shared';
import {AccessTokenApiInterceptor, AuthenticationModule} from 'authentication';
import {MdefAuthenticationService} from 'mdef-services';
import {MdefErrorHandlerService} from "../../../../library/shared/src/lib/error-handling/mdef-error-handler.service";

export const ACCESS_TOKEN_API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => AccessTokenApiInterceptor),
  multi: true
};

export const appConfig: ApplicationConfig = {
  providers: [
    ACCESS_TOKEN_API_INTERCEPTOR_PROVIDER,
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(AuthenticationModule.forRoot(environment, MdefAuthenticationService)),
    provideRouter(routes),
    { provide: BASE_PATH, useValue: environment.mdefService },
    provideServiceWorker('ngsw-worker.js',
      {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }), provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    { provide: ErrorHandler, useClass: MdefErrorHandlerService },
     importProvidersFrom(SharedModule.forRoot(environment))]
};
