<div class="explanation">
  <h2 class="explanation-heading">{{ explanation }}</h2>
  <form class="explanation-form" [formGroup]="explanationForm" (ngSubmit)="onExplanationSubmit()">
    <div class="explanation-form__textarea-wrapper">
      <textarea #textArea rows="6" type="text" formControlName="explanation"></textarea>
      <div class="explanation-form__error" *ngIf="!explanationForm.valid && showError">* Dit veld is verplicht
      </div>
    </div>
    <button class="explanation-form__submit" type="submit">Verstuur</button>
  </form>
</div>
