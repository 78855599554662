import {Injectable} from "@angular/core";

@Injectable()
export abstract class SharedModuleConfig {
  abstract production: boolean;
  abstract keycloakUrl: string;
  abstract clientId: string;
  abstract clientSecret: string;
  abstract realm: string;
  abstract appInsights: {
    instrumentationKey: string;
  }
}
