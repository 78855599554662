<div class="mdef-sign-up-form">
  <form
    action=""
    [formGroup]="nickNameForm"
    (ngSubmit)="onNickNameFormSubmit()"
  >
    <div class="form-field">
      <label for="veldID" class="form-label">Kim ID</label>
      <div class="input-wrapper">
        <input
          formControlName="nickname"
          type="text"
          id="veldID"
          class="form-control"
          [class.form-control--invalid]="showFormErrors && nickNameForm.get('nickname')?.errors"
          required
        />
        @if(showFormErrors && nickNameForm.get('nickname')?.errors) {
          <p aria-role="alert" class="form-validation">Dit is verplicht</p>
        }
      </div>
    </div>
    <div class="form-field">
      <label for="groupId" class="form-label">Groep</label>
      <div class="input-wrapper">
        <select
          formControlName="groupId"
          type="text"
          id="groupId"
          class="form-control form-select"
          [class.form-control--invalid]="showFormErrors && nickNameForm.get('groupId')?.errors"
          required
        >
          @for (group of groups; track $index) {
            <option [value]="group.id">{{ group.groupName }}</option>
          }
        </select>
        @if(showFormErrors && nickNameForm.get('groupId')?.errors) {
          <p aria-role="alert" class="form-validation">Dit is verplicht</p>
        }
      </div>
    </div>
    <div class="form-field">
      <label for="moduleId" class="form-label">VML</label>
      <div class="input-wrapper">
        <select
          formControlName="moduleId"
          type="text"
          id="moduleId"
          class="form-control form-select"
          [class.form-control--invalid]="showFormErrors && nickNameForm.get('moduleId')?.errors"
          required
        >
          @for (module of modules; track $index) {
          <option [value]="module.id">{{ module.name }}</option>
          }
        </select>
        @if(showFormErrors && nickNameForm.get('moduleId')?.errors) {
          <p aria-role="alert" class="form-validation">Dit is verplicht</p>
        }
      </div>
    </div>
    <button
      type="submit"
      class="mdef-sign-up-form__submit"
    >
      Opslaan
    </button>
  </form>

</div>
