import {SharedModuleConfig} from "./lib/config/shared-module-config";
import {ModuleWithProviders, NgModule} from "@angular/core";
@NgModule({
  declarations: [],
  imports: [],
})
export class SharedModule {
  static forRoot(config: SharedModuleConfig): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [{ provide: SharedModuleConfig, useValue: config }],
    };
  }
}
