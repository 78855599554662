<button class="game-map__back" (click)="onBackClicked()">
  <tngh-svg-icon [style.color]="'white'" name="arrow-left" size="2xl"></tngh-svg-icon>
  <span>Terug</span>
</button>
<div class="game-map">
  <h1 class="game-map__title">Selecteer inject</h1>

  <div class="game-map__bg--container" #currentMap>
    <img
      src="./assets/images/kaart-texel.jpg"
      class="game-map__bg"
      alt="Een kaart van Texel"
      [height]="mapHeight"
      [width]="mapWidth"
      decoding="async"
    />

    @for (inject of injects; track inject.id; let index = $index) {
      <button [class]="'game-map__button game-map__button--' + index"
              [class.finished]="index < currentInject"
              [disabled]="!inject.playable"
              [class.current]="index === currentInject"
              (click)="onSelectInject(inject.id, inject.playable)">
        <span class="sr-only">Start {{inject.description}}</span>
      </button>
    }

    @if (currentInjectArrow !== 0) {
      <img
        [src]="'./assets/images/map-inject-1.png'"
        class="arrow arrow-1"
        [class.inactive]="currentInjectArrow < 1"
        height="1080"
        width="1920"
        alt=""
        decoding="async"
      />
      <img
        [src]="'./assets/images/map-inject-6.png'"
        class="arrow arrow-6"
        [class.inactive]="currentInjectArrow < 6"
        height="1080"
        width="1920"
        alt=""
        decoding="async"
      />
      <img
        [src]="'./assets/images/map-inject-7.png'"
        [class.inactive]="currentInjectArrow < 7"
        height="1080"
        width="1920"
        alt=""
        class="arrow arrow-7"
        decoding="async"
      />
      <img
        [src]="'./assets/images/map-inject-11.png'"
        [class.inactive]="currentInjectArrow < 11"
        height="1080"
        width="1920"
        alt=""
        class="arrow arrow-11"
        decoding="async"
      />
    }
  </div>
  <ul class="timeline">
    @for (inject of injects; track inject.id; let index = $index) {
      @if (inject.playable) {
        <li class="timeline__item">
          <button
            [class]="'timeline__item__content ' + 'timeline__item--' + index"
            type="button"
            (click)="onSelectInject(inject.id)"
          >
            {{ inject.description }}
          </button>
        </li>
      } @else {
        <li class="timeline__item">
          <div class="timeline__item__content">
            <span class="sr-only">Dit level is nog niet te spelen</span>
            <img
              src="./assets/images/mdef-icon-lock.svg"
              height="40"
              width="40"
              decoding="async"
              alt="Slot icoon"
            />
          </div>
        </li>
      }
    }
  </ul>
</div>

@if (injectFinished) {
  <div class="finished-screen">
    <h1 class="finished-screen__title">De game stopt voor nu. Zoek contact met je begeleider om het inject te
      evalueren</h1>
    <button (click)="injectFinished = false">Verder</button>
  </div>
}
