import {Component} from '@angular/core';
import {AsyncPipe, JsonPipe, NgIf} from "@angular/common";
import {IntroScreenComponent} from "../../intro-screen/intro-screen.component";
import {QuizService} from "../../services/quiz.service";
import {combineLatestWith, map, Observable, tap} from "rxjs";
import {Async, Question} from "shared";
import {ActivatedRoute, NavigationStart, Router, RouterOutlet} from "@angular/router";
import {TopbarComponent} from "../../topbar/topbar.component";
import {
  GroupControllerService,
  GroupDTO,
  InjectControllerService,
  InjectDTO, ModuleControllerService,
  ModuleDTO, PlayerDTO,
  ResultDTO
} from "mdef-api";
import {SignUpFormComponent} from "../../nickname/sign-up-form.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Location} from '@angular/common';
import {GameMapComponent} from "../../game-map/game-map.component";

@UntilDestroy()
@Component({
  selector: 'app-signup-page',
  standalone: true,
  imports: [
    AsyncPipe,
    IntroScreenComponent,
    NgIf,
    RouterOutlet,
    TopbarComponent,
    GameMapComponent,
    SignUpFormComponent,
    JsonPipe
  ],
  templateUrl: './signup-page.component.html',
  styleUrl: './signup-page.component.scss'
})
export class SignupPageComponent {
  question$: Observable<Question | null>;

  selectableInjects: Observable<{ id: number, description: string, playable: boolean }[]>;
  currentStep: number = 1;
  injectFinished: boolean = false;
  groups: Async<Array<GroupDTO>>;
  injects: Async<Array<InjectDTO>>;
  modules: Async<Array<ModuleDTO>>;

  private playerDetails: Partial<ResultDTO> = {};

  constructor(private quizService: QuizService, private router: Router, injectControllerService: InjectControllerService, private route: ActivatedRoute, groupControllerService: GroupControllerService,
              moduleControllerService: ModuleControllerService, private _location: Location) {
    router.events.pipe(
      untilDestroyed(this)
    ).subscribe((event) => {
      if (!(event instanceof NavigationStart) || event.navigationTrigger === 'popstate') {
        this.injectFinished = this.route.snapshot.queryParams['injectFinished'] !== undefined;
        const queryParams: Partial<ResultDTO> = this.route.snapshot.queryParams;
        this.restoreCurrentStep(queryParams);
      }
    });

    this.question$ = quizService.currentQuestion$;
    this.selectableInjects = injectControllerService.getAllInjects().pipe(
      untilDestroyed(this),
      combineLatestWith(this.route.queryParams),
      map(SignupPageComponent.determinePlayableInjects)
    );

    groupControllerService
      .findAllGroups(true)
      .subscribe((groups) => (this.groups = groups));
    injectControllerService
      .getAllInjects()
      .subscribe((injects) => (this.injects = injects));
    moduleControllerService
      .getAllModules(true)
      .subscribe((modules) => (this.modules = modules));

  }

  onNickNameFormSubmit($event: Partial<ResultDTO>) {
    this.quizService.playerDetails = $event;
    this.router.navigate([], {
      queryParams: {
        groupId: $event.groupId,
        nickname: $event.nickname,
        moduleId: $event.moduleId
      },
      relativeTo: this.route
    });
    this.currentStep++;
  }

  onFieldClicked(field: string) {
    this.playerDetails = {...this.playerDetails, field};
    this.router.navigate([], {
      queryParams: {
        field
      },
      queryParamsHandling: "merge",
      relativeTo: this.route
    });
    this.currentStep++;
  }

  onSetInject(injectId: number) {
    this.playerDetails = {...this.playerDetails, injectId};
    this.quizService.playerDetails = this.playerDetails;
    this.router.navigate(['quiz']);
  }

  onPageBack(): void {
    switch (this.currentStep) {
      case 2:
        this.router.navigate([], {
          queryParams: {}, relativeTo: this.route});
        break;
      case 3:
        this.router.navigate([], {
          queryParams: {
            groupId: this.playerDetails.groupId,
            nickname: this.playerDetails.nickname,
            moduleId: this.playerDetails.moduleId
          },
          relativeTo: this.route
        });
        break;
      default:
        return;
    }
  }

  private restoreCurrentStep(queryParams: Partial<ResultDTO>) {
    this.playerDetails = queryParams;
    if (queryParams.field) {
      this.currentStep = 3;
    } else if (queryParams.moduleId && queryParams.groupId) {
      this.currentStep = 2;
    } else {
      this.currentStep = 1;
    }
  }

  private static determinePlayableInjects = ([injects, currentResultDTO]: [InjectDTO[], ResultDTO]) => {
    injects = injects
      .sort((i1, i2) => (i1.id ?? 0) - (i2.id ?? 0))
      .filter(inject => inject.field === currentResultDTO.field || inject.field === 'ALL');

    return new Array(5)
      .fill({id: 0, description: '', playable: false})
      .map((nonExistingInject, i) => injects[i] ? {
          id: injects[i].id ?? 0,
          description: injects[i].name ?? '',
          playable: i === 0 || !injects.slice(0, i).some(
            inject => !inject.results?.some(result => result?.nickname === currentResultDTO.nickname)
          )
      } : nonExistingInject);
  }
}
