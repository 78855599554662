<div class="character__list">
  <div class="character__persona" *ngFor="let character of characters">
    <div class="character-image-frame">
      <img *ngIf="character?.introImage" ngSrc="{{character?.introImage}}" width="360" height="551">
    </div>
    <h2 class="character__persona__label">{{ character.label }}</h2>
    <button type="button" class="character__persona__btn" (click)="onFieldClicked(character.label)"
            [attr.aria-label]="'Kies de richting ' + character.label"></button>
  </div>
</div>
