import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Size, Variants, AvailableIcons } from 'shared';

@Component({
  selector: 'tngh-svg-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnChanges {
  link = '';
  @Input() name?: AvailableIcons;
  @Input() size: Size = 'lg';
  @Input() variant: Variants = 'primary';

  ngOnChanges(): void {
    this.link = `assets/icons/icons.svg#${this.name}`;
  }
}
