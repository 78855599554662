import {Injectable} from '@angular/core';
import {
  BehaviorSubject, debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  of,
  ReplaySubject, skip,
  Subject,
  switchMap,
  takeUntil, tap,
  timer,
} from 'rxjs';
import {AnswerControllerService, ResultControllerService, ResultDTO,} from 'mdef-api';
import {Answer, IntermediateResult, Question, QuestionAnswerService} from 'shared';
import {environment} from '../../environments/environment';
import {QuestionAnswersPerInjectService} from "mdef-services";
import {Router} from "@angular/router";

const emptyCurrentStep = {
  question: null,
  answer: null,
  showQuestion: false,
  intermediateResult: null
}

@Injectable({providedIn: 'root'})
export class QuizService {
  private resultId = new ReplaySubject<number>(1);
  private currentStep = new BehaviorSubject<{
    question: Question | null,
    showQuestion: boolean,
    answer: { answer?: Answer, time?: number, givenExplanation?: string } | null,
    intermediateResult: IntermediateResult | null
  }>(emptyCurrentStep);
  private questionStartTime = 0;
  private _playerDetails: Partial<ResultDTO> = {};

  private onReset: Subject<void> = new Subject();

  currentPlayerDetails: BehaviorSubject<Partial<ResultDTO>> = new BehaviorSubject<Partial<ResultDTO>>(this._playerDetails);

  constructor(
    private resultControllerService: ResultControllerService,
    private answerControllerService: AnswerControllerService,
    private questionAnswerService: QuestionAnswerService,
    private questionAnswersPerInjectService: QuestionAnswersPerInjectService,
    private router: Router
  ) {
    this.reset();
  }

  get currentAnswer$() {
    return this.currentStep.pipe(map(currentStep => currentStep.answer));
  }

  get currentQuestion$() {

    return this.currentStep.pipe(map(currentStep => currentStep.question));
  }

  get currentStep$() {
    return this.currentStep.asObservable();
  }

  set answer(answer: { answer?: Answer, time?: number, givenExplanation?: string }) {
    this.saveAnswer(answer);
    const questionIntermediateResult = (this.currentStep.value.question && 'intermediateResult' in this.currentStep.value.question && this.currentStep.value.question.intermediateResult);

    if (this.currentStep.value.question && 'followUpQuestionId' in this.currentStep.value.question && this.currentStep.value.question.followUpQuestionId) {
      this.selectQuestion(this.currentStep.value.question.followUpQuestionId);
    } else {
      this.currentStep.next({...emptyCurrentStep, answer, showQuestion: false});
      if (answer.answer?.followUpQuestionId) {
        this.selectQuestion(answer.answer.followUpQuestionId);
      } else if (answer.answer && answer.answer.intermediateResult) {
        this.currentStep.next({...emptyCurrentStep, intermediateResult: answer.answer.intermediateResult});
      } else if (questionIntermediateResult) {
        this.currentStep.next({...emptyCurrentStep, intermediateResult: {...questionIntermediateResult}});
      } else {
        this.exitQuiz();
      }
    }
  }

  private saveAnswer(answer: { answer?: Answer, time?: number, givenExplanation?: string }) {
    const answerTimeInMS = (this.questionStartTime > 0 && answer.time) ? answer.time - this.questionStartTime : 0;

    this.resultId.pipe(
      first(),
      map((id) => ({
        answerId: answer.answer?.id,
        questionId: this.currentStep.value.question?.id,
        resultId: id,
        amount: answer.answer?.amount ? answer.answer?.amount : '',
        explanation: answer.givenExplanation,
        answerTimeInMS,
      })),
      switchMap((postBody) =>
        this.answerControllerService.saveAnswer(postBody)
      )).subscribe();
  }

  private selectQuestion(questionId: number) {
    this.questionAnswerService.getQuestionById(questionId).pipe(
      first(),
      filter(Boolean)
    ).subscribe((question) => {
      this.currentStep.next({...emptyCurrentStep, question, showQuestion: false});
    });
  }

  private startTimerForQuestion(question: Question) {
    timer(environment.skipQuestions ? 1000 : question.timestamp * 1000).pipe(
      takeUntil(this.onReset),
    ).subscribe(() => {
      this.questionStartTime = Date.now();
      this.currentStep.next({...this.currentStep.value, showQuestion: true})
    });
  }

  set playerDetails(playerDetails: ResultDTO) {
    this._playerDetails = {
      ...this._playerDetails,
      ...playerDetails,
    };
    if (this._playerDetails.injectId) {
      const questionsAndAnswers = this.questionAnswersPerInjectService.getQuestionAnswerForInject(this._playerDetails.injectId);
      if (questionsAndAnswers) {
        this.questionAnswerService.setup(questionsAndAnswers);
      }
    }

    this.currentPlayerDetails.next(this._playerDetails);
    this.reset();
  }

  reset() {
    this.onReset.next();
    this.currentStep.next({...emptyCurrentStep});
    if(this._playerDetails.injectId === 11) {
      this.currentStep.next({
        ...emptyCurrentStep,
        intermediateResult: {videoPath: 'assets/videos/inject-11/KIM-Intro+Inject11+Outro.mp4'}
      })
    } else {
      this.questionAnswerService.getFirstQuestion().pipe(first()).subscribe(question => {
        this.currentStep.next({...emptyCurrentStep, question});
      });
    }
  }

  createResult(): void {
    this.reset();

    const subscription = this.currentPlayerDetails.pipe(
      filter((result) => !!result.nickname && !!result.groupId && !!result.moduleId && !!result.field && !!result.injectId),
      distinctUntilChanged<Partial<ResultDTO>>((d1: Partial<ResultDTO>, d2: Partial<ResultDTO>) =>
        d1.nickname === d2.nickname && d1.injectId === d2.injectId
      ),
      switchMap(result => this.resultControllerService.createResult(result)),
      map((res) => res.id),
      filter(Boolean)
    ).subscribe(id => {
      this.resultId.next(id)
      subscription.unsubscribe();
    });

    this.currentStep.pipe(
      map(step => step.question),
      distinctUntilChanged((q1, q2) => q1?.id === q2?.id),
      filter(Boolean)
    ).subscribe(question => {
      this.startTimerForQuestion(question);
    });
  }

  continue() {
    const {intermediateResult, question, answer} = this.currentStep.value;

    if (intermediateResult) {
      this.showIntermediateResult(intermediateResult);

    } else {
      if(!question && !answer) {
        this.exitQuiz();
      }
    }
  }

  private showIntermediateResult(intermediateResult: IntermediateResult) {
    const nextQuestion: Observable<Question | null | undefined> = intermediateResult.nextQuestionId ? this.questionAnswerService.getQuestionById(intermediateResult.nextQuestionId) : of(null);
    nextQuestion.pipe(first()).subscribe(question => {
      if(question || intermediateResult.nextIntermediateResult) {
        this.currentStep.next({
          intermediateResult: intermediateResult.nextIntermediateResult ?? null,
          showQuestion: false,
          question: question ?? null,
          answer: null
        });
      } else {
        this.exitQuiz();
      }
    })
  }

  private exitQuiz() {
    const {groupId, nickname, moduleId, field} = this.currentPlayerDetails.value;
    this.router.navigate(['signup'], {
      queryParams: {
        groupId,
        nickname,
        moduleId,
        field,
        injectFinished: true
      }
    });
  }
}
