import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { SvgIconComponent } from '../../../../../library/component-library/src/lib/base/svg-icon/svg-icon.component';
import { Async } from 'shared';
import {QuizService} from "../services/quiz.service";

interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

@Component({
  selector: 'mdef-game-map',
  standalone: true,
  templateUrl: './game-map.component.html',
  styleUrl: './game-map.component.scss',
  imports: [SvgIconComponent],
})
export class GameMapComponent implements AfterViewInit {
  @Input() injects: Async<{ id: number, description: string, playable: boolean }[]> = [];
  @Input() injectFinished: boolean = false;
  @Output() selectInject = new EventEmitter<number>();
  @Output() pageBack = new EventEmitter<boolean>();
  currentInject: number = 0;

  get currentInjectArrow() {
    if (this.injects) {
      const currentInjects = this.injects.filter((inject) => inject.playable);
      this.currentInject = currentInjects.length - 1;
      switch (this.currentInject) {
        case 1:
          return 1;
        case 2:
          return 6;
        case 3:
          return 7;
        case 4:
          return 11;
      }
    }
    return 0;
  }

  mapWidth: number = 1920;
  mapHeight: number = 1080;
  elem: HTMLElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };

  onSelectInject(injectId: number, injectActive: boolean = true) {
    if (injectActive) {
      this.openFullscreen();
      this.selectInject.next(injectId);
      this.quizService.createResult();
    }
  }

  constructor(private quizService: QuizService) {
    this.elem = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
  }

  onBackClicked(): void {
    this.pageBack.emit();
  }

  isFullScreen(): boolean {
    const fsDoc = <FsDocument> document;

    return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
  }

  @ViewChild('currentMap') currentMap?: ElementRef<HTMLImageElement>;

  ngAfterViewInit(): void {
    this.closeFullscreen();
    this.updateOverlayPosition();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateOverlayPosition();
  }

  private updateOverlayPosition(): void {
    const scaleWidth: number = window.innerWidth * 100 / this.mapWidth - 2;
    const scaleHeight: number = window.innerHeight * 100 / this.mapHeight - 2;

    if (this.currentMap) {
      this.currentMap.nativeElement.style.setProperty(
        '--scale',
        `${scaleHeight < scaleWidth ? scaleHeight / 100 : scaleWidth / 100}`)
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    const fsDoc = <FsDocument> document;
    if (!this.isFullScreen()) return;

    if (fsDoc.exitFullscreen)
      fsDoc.exitFullscreen();
    else if (fsDoc.msExitFullscreen)
      fsDoc.msExitFullscreen();
    else if (fsDoc.mozCancelFullScreen)
      fsDoc.mozCancelFullScreen();
    else if (fsDoc.webkitExitFullscreen)
      fsDoc.webkitExitFullscreen();
  }

}
