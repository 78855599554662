@if (currentStep === 1) {
  <div class="signup">
    <app-topbar [videoTitle]=""/>
    <div class="intro-screen__bg"></div>
    <h1 class="intro-screen__title">Registratie</h1>
    <app-sign-up-form [groups]="groups" [injects]="injects" [modules]="modules"
                      (nickNameFormSubmit)="onNickNameFormSubmit($event)"/>
  </div>
} @else if (currentStep === 2) {
  <div class="signup">
    <app-topbar [videoTitle]=""></app-topbar>
    <div class="intro-screen__bg"></div>
    <h1 class="intro-screen__title">Kies je richting</h1>
    <app-intro-screen (fieldClicked)="onFieldClicked($event)" (pageBack)="onPageBack()"/>
  </div>
} @else if (currentStep === 3) {
  <mdef-game-map [injects]="selectableInjects | async" [injectFinished]="injectFinished" (selectInject)="onSetInject($event)" (pageBack)="onPageBack()"></mdef-game-map>
}
