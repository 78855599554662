@if (question && !showExplanation) {
  @switch (question.type) {
    @case ('budget') {
      <div class="question-wrapper" [ngClass]="{timedOut: timedOut}">
        <div class="question">
          <h2 class="question__heading">{{ question.label }}</h2>
        </div>
        <div class="currency-wrapper">
          <app-currency-input [questionLength]="3" [answers]="question.answers"
                              (valueSubmit)="onAnswerClicked($event)"></app-currency-input>
        </div>

        @if (!timedOut) {
        <div class="question__timer"
             [ngClass]="{timedOut: timedOut}" [style.--question-timer]="question.questionTime + 's'" (animationend)="timedOut = true"></div>
        }
      </div>
    }
    @case ('open') {
      <app-explanation [explanation]="question.label" (explanationSubmit)="onExplanationSubmit($event)"/>
    }
    @default {
      <div class="question-wrapper" [ngClass]="{timedOut: timedOut}">
        <div class="question">
          <h2 class="question__heading">{{ question.label }}</h2>
        </div>
        <div class="answer-wrapper">
          <button class="answer-btn" *ngFor="let answer of question?.answers" (click)="onAnswerClicked(answer)">
            <span class="answer-btn__text">{{ answer.label }}</span>
          </button>

        </div>
        @if (!timedOut) {
          <div class="question__timer" [ngClass]="{timedOut: timedOut}" [style.--question-timer]="question.questionTime + 's'" (animationend)="timedOut = true"></div>
        }
      </div>
    }
  }
} @else {
  <app-explanation *ngIf="showExplanation" [explanation]="givenAnswer?.answer?.explanation"
                   (explanationSubmit)="onExplanationSubmit($event)"/>
}

