<app-video-player
    [play]="play$"
    [pause]="pause$"
    *ngIf="videoPath"
    [videoPath]="videoPath"
    (videoEnd)="onVideoEnd()"
  />
@if(showQuestion) {
  <app-question-viewer [question]="question$ | async" (answerClicked)="onAnswerClicked($event)"/>
}
