import {Question} from "shared";

export const questions: Question[] = [
    {
      id: 1,
      label: 'Wat wil je gaan doen?',
      questionTime: 20,
      videoPath: 'assets/videos/inject-1/KIM-Inject1-Intro+S1.mp4',
      timestamp: 112,
      answers: [
        {
          id: 2,
          label: 'Licht de CDT in en wacht op orders',
          hasExplanation: true,
          explanation: 'kun je je antwoord toelichten?',
          followUpQuestionId: 2
        },
        {
          id: 6,
          label: 'Blijf bij je orders',
          hasExplanation: true,
          explanation: 'kun je je antwoord toelichten?',
          followUpQuestionId: 3
        },
        {
          id: 10,
          label: 'Verleg je koers naar het ongeïdentificeerde contact',
          hasExplanation: true,
          explanation: 'kun je je antwoord toelichten?',
          followUpQuestionId: 4,
        }
      ]
    },
  {
    id: 2,
    label: 'Geef de CDT een advies:',
    questionTime: 20,
    videoPath: 'assets/videos/inject-1/KIM-Inject1-S2A.mp4',
    timestamp: 111,
    answers: [
      {
        id: 3,
        label: 'Naar het RDV gaan',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S3A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 4,
        label: 'Neem je tijd en observeer',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S3B.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 5,
        label: 'Haal alle benodigde rollen op post',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S3C.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      }
    ]
  },
  {
    id: 3,
    videoPath: 'assets/videos/inject-1/KIM-Inject1-S2B.mp4',
    questionTime: 20,
    label: 'welke vervolgactie neem je?',
    timestamp: 61,
    answers: [
      {
        id: 7,
        label: 'Informeer de CDT over afwezigheid Zr Ms Den Helder.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S4A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 8,
        label: 'Probeer alsnog de Zr Ms Den Helder te vinden',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S4B.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 9,
        label: 'Informeer de CDT en ga alsnog de boot in nood helpen',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S4C.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      }
    ]
  },
  {
    id: 4,
    label: 'Welke stap is nu de beste?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-1/KIM-Inject1-S2C.mp4',
    timestamp: 26,
    answers: [
      {
        id: 11,
        label: 'Je stelt voor om te gaan helpen',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S5A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 12,
        label: 'Je ziet in dat je met de missie verder moet',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S5B.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 13,
        label: 'Je adviseert om met een RHIB poolshoogte te nemen',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S5C.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      }
    ]
  }
]

export const firstQuestion: number = 1;
