import { Question } from "shared";

export const questions: Question[] = [
  {
    id: 1,
    label: 'Wat doe je?',
    questionTime: 20,
    timestamp: 98,
    videoPath: 'assets/videos/inject-4/KIM-Inject4-Intro+S1.mp4',
    answers: [
      {id: 10, followUpQuestionId: 2, label: 'Blijf als SVO bij je punt. Het kan niet, je wil geen schade', hasExplanation: true, explanation: 'kun je je antwoord toelichten?',},
      {id: 11, followUpQuestionId: 3, label: 'Luister naar de OVDW en volg de orders van de CDT', hasExplanation: true, explanation: 'kun je je antwoord toelichten?',},
    ]
  }, {
    id: 2,
    label: 'Hoe reageer je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-4/KIM-Inject4-S2A.mp4',
    timestamp: 32,
    answers: [
      {
        id: 12,
        label: 'Blijf bij je standpunt',
        intermediateResult: {
          videoPath: 'assets/videos/inject-4/KIM-Inject4-S3A.mp4',
          nextQuestionId: 4
        }
      },
      {
        id: 13,
        label: 'Je veranderd je standpunt, de OVDW heeft gelijk',
        intermediateResult: {
          videoPath: 'assets/videos/inject-4/KIM-Inject4-S3B.mp4',
          nextQuestionId: 4
        }
      },
    ]
  }, {
    id: 3,
    label: 'Hoe reageer je?',
    questionTime: 20,
    timestamp: 53,
    videoPath: 'assets/videos/inject-4/KIM-Inject4-S2B.mp4',
    answers: [
      {
        id: 14,
        label: 'Wees empathisch en leg uit dat de CDT heeft besloten',
        intermediateResult: {
          videoPath: 'assets/videos/inject-4/KIM-Inject4-S3C.mp4',
          nextQuestionId: 4
        }
      },
      {
        id: 15,
        label: 'De risico’s zijn duidelijk, we gaan toch door' ,
        intermediateResult: {
          videoPath: 'assets/videos/inject-4/KIM-Inject4-S3D.mp4',
          nextQuestionId: 4
        }
      }
    ]
  },
  {
    id: 4,
    type: 'open',
    questionTime: 20,
    label: 'Kun je de gemaakte keuzes beargumenteren',
    timestamp: 18,
    videoPath: 'assets/videos/inject-4/KIM-Inject4-S4.mp4',
    intermediateResult: {
      videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4'
    }
  }
];

export const firstQuestion: number = 1;
