import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {VideoPlayerComponent} from "./video-player/video-player.component";
import {QuestionViewerComponent} from "./question-viewer/question-viewer.component";
import {CommonModule} from "@angular/common";
import {TopbarComponent} from "./topbar/topbar.component";
import {IntroScreenComponent} from "./intro-screen/intro-screen.component";
import {ExplanationComponent} from "./explanation/explanation.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, VideoPlayerComponent, QuestionViewerComponent, TopbarComponent, ExplanationComponent, IntroScreenComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

}
