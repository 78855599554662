import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InjectBudgetService {
  currentBudgetSelection: number[] = [];

  get totalBudgetSelection(): number {
    return this.currentBudgetSelection.reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    },0);
  }

  addBudgetExpense(expense: number): void {
    this.currentBudgetSelection.push(expense);
  }

  resetBudgets(): void {
    this.currentBudgetSelection = [];
  }
}
