import {Question} from "shared";

export const questions: Question[] = [
  {
    id: 1,
    label: 'Wat is je order?',
    questionTime: 45,
    videoPath: 'assets/videos/inject-7/KIM-Inject7-Intro+S1.mp4',
    timestamp: 117,
    answers: [
      {
        id: 1,
        label: 'Iedereen op post, focus verdediging gericht op de kade',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 2
      },
      {
        id: 2,
        label: 'Schip gereed maken voor vertrek, personeel focus op het vertrek',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 5
      }
    ]
  },
  {
    id: 2,
    label: 'Hoe reageer je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-7/KIM-Inject7-S2A.mp4',
    timestamp: 61,
    answers: [
      {
        id: 3,
        label: 'Voeg je naar de mening van de commandant',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 3,
      },
      {
        id: 4,
        label: 'Blijf bij je keuze ondanks de reactie van de commandant',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 4
      }
    ]
  },
  {
    id: 5,
    label: 'Wat doe je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-7/KIM-Inject7-S2B.mp4',
    timestamp: 59,
    answers: [
      {
        id: 5,
        label: 'Je voegt je naar de mening van de CTD.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 6
      },
      {
        id: 6,
        label: 'Je blijft bij je keuze ondank het gedrag van de CDT.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 7
      }
    ]
  },
  {
    id: 3,
    label: 'Het gedrag escaleert, zou je alsnog iets zeggen van het gedrag?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-7/KIM-Inject7-S3A.mp4',
    timestamp: 15,
    answers: [
      {
        id: 7,
        label: 'Iets van het gedrag zeggen',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-7/KIM-Inject7-S4B(vervolgop3A).mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      },
      {
        id: 8,
        label: 'Houd je mond over het gedrag',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-7/KIM-Inject7-S4B(vervolgop3B).mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      }
    ]
  },
  {
    id: 4,
    label: 'Hoe reageer je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-7/KIM-Inject7-S3B.mp4',
    timestamp: 11,
    answers: [
      {
        id: 13,
        label: 'Voeg je naar de mening van de commandant',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-7/KIM-Inject7-S4B(vervolgop3A).mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      },
      {
        id: 14,
        label: 'Blijf bij je keuze ondanks de reactie van de commandant',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-7/KIM-Inject7-S4B(vervolgop3B).mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      }
    ]
  },
  {
    id: 6,
    label: 'Leg uit',
    questionTime: 20,
    videoPath: 'assets/videos/inject-7/KIM-Inject7-S3A.mp4',
    timestamp: 15,
    answers: [
      {
        id: 9,
        label: 'Zeg iets van het gedrag van CDT',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-7/KIM-Inject7-S4A(vervolgop3A).mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      },
      {
        id: 10,
        label: 'Houd je mond over het gedrag van CDT',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-7/KIM-Inject7-S4B(vervolgop3A).mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      }
    ]
  },
  {
    id: 7,
    label: 'Leg uit',
    questionTime: 20,
    videoPath: 'assets/videos/inject-7/KIM-Inject7-S3B.mp4',
    timestamp: 11,
    answers: [
      {
        id: 11,
        label: 'Zeg iets van het gedrag van CDT',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-7/KIM-Inject7-S4A(vervolgop3B).mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      },
      {
        id: 12,
        label: 'Houd je mond over het gedrag van CDT',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-7/KIM-Inject7-S4B(vervolgop3B).mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
          }
        }
      }
    ]
  }
]

export const firstQuestion: number = 1;
